import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const Modal = ({
  isActive,
  children,
  title,
  handleClose,
  hasCloseButton,
  maxWidth,
  styleTitle,
  iconTitle,
  styleIcon,
  fullScreenChildren,
  className,
}) => {
  if (hasCloseButton == null || hasCloseButton == undefined) hasCloseButton = true;
  return (
    <Dialog
      fullWidth={false}
      maxWidth={maxWidth || false}
      open={isActive}
      onClose={() => handleClose(false)}
      aria-labelledby="max-width-dialog-title"
      className={
        className
          ? className
          : `modal1 ${fullScreenChildren ? "full-screen" : ""}`
      }
    >
      {hasCloseButton === true && (
        <div className="close">
          <Tooltip title="Cerrar">
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {title ||
        (iconTitle && (
          <div className={styleTitle}>
            {iconTitle && (
              <img
                src={iconTitle}
                alt={title || "Ícono"}
                className={styleIcon}
              />
            )}
            {title && <DialogTitle>{title}</DialogTitle>}
          </div>
        ))}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
