import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import ResultDefault from "./Default";
import TestConnection from "./TestConnection";
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  getAllPatientQueuesWaitingAccepted,
  getEstimatedTime,
  getPatientsQueue,
} from "../../../services/queueService";
import { useStateAuthValue } from "../../../context/Auth/AuthState";
import { useStateProfConsultFilterValue } from "../../../context/profConsultFilterState";
import { GetProfessionalProfileById } from "../../../services/professionalService";
import { FilterType } from "../../../utils/FilterUtil";
import { ApplicationStyle } from "../../../theme";
import {
  checkStatus,
  processEmail,
  showToast,
  ToastType,
} from "../../../utils/ToastUtil";
import { useToast } from "../../../context/Toast/ToastProvider";
import PhoneIcon from "@material-ui/icons/Phone";
import LoadingSpinner from "../../../components/LoadingSpinner";
import dialtone from "../../../assets/notifications/dialtone.mp3";
import CancelIcon from "@material-ui/icons/Clear";
import firebase from "../../../services/firebase";
import CustomizedTables from "../../../components/FormFields/CustomizedTables";
import {
  formatDateHourAndMinute,
  formatDateTime,
} from "../../../utils/DateUtil";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Modal from "../../../components/Modal";

const useStyles = () => ({
  tableContainer: {
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    minWidth: "400px",
  },
  head: {
    backgroundColor: "#002C54",
    color: "white",
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
  odd: {
    backgroundColor: "#f9f9f9",
  },
  disabled: {
    color: "gray",
  },
});
const PatientResult = ({ audioElement, stopRingCall }) => {
  const classes = useStyles();
  const MedilineStyle = makeStyles(ApplicationStyle);
  const MedilineClasses = MedilineStyle();
  const toastDispatch = useToast();
  const [{ clickButton }, dispatch] = useStateProfConsultFilterValue();
  const [{ data, loading, stomp }] = useStateProfConsultFilterValue();
  const [{ userData, userToken }] = useStateAuthValue();
  const [profName, setProfName] = useState("");
  //const [profImage, setProfimage] = useState();
  //eslint-disable-next-line
  const [queueLength, setQueueLength] = useState(0);
  const [disableCancelButton, setDisableCancelButton] = useState(true);

  useEffect(() => {
    if (clickButton === FilterType.SEARCH_PROFESSIONAL) {
      setTimeout(() => {
        setDisableCancelButton(false);
      }, 3000);
    }
  }, [clickButton]);

  useEffect(() => {
    if (data?.professionalId) {
      const getProfData = async () => {
        await GetProfessionalProfileById(userToken, data?.professionalId)
          .then((res) => {
            setProfName(res.data.first_name + " " + res.data.last_name);
            /* setProfimage(
              `data:image/png;base64,${res.data.professional_dto.photo_encode}`
            ); */
          })
          .catch((e) => {
            showToast(
              toastDispatch,
              e.message,
              ToastType.ERROR,
              ToastType.ERROR
            );
          });
      };
      getProfData();
    }
  }, [data]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     getQueueInfo();
  //   }, 1000);
  //   const intervalId = setInterval(() => {
  //     getQueueInfo();
  //   }, 10000);
  //   return () => clearInterval(intervalId);
  // }, [data]);

  useEffect(() => {
    // Function to play the audio and loop it
    if (clickButton === FilterType.SEARCH_PROFESSIONAL) {
      // Play the audio
      audioElement.current.play();

      audioElement.current.addEventListener(
        "ended",
        () => {
          audioElement.current.currentTime = 0;
          audioElement.current.play();
        },
        false
      );

      getQueue();
    } else {
      clearInterval(intervalId);
      stopRingCall();
    }
    return stopRingCall;
  }, [clickButton]);
  const [patientsBeforeMe, setPatientsBeforeMe] = useState([]);
  let intervalId = null;
  const getQueue = async () => {
    var queue = await getAllPatientQueuesWaitingAccepted(
      userToken,
      data?.specialtyId
    );

    if (queue && userData) {
      var idPaciente = userData.patient_dto.auth_id; // Cambia esto por tu valor real de idPaciente
      var miPaciente = queue.find(
        (paciente) => paciente.patientid == idPaciente
      );
      // Filtrar el array para eliminar al paciente con tu id
      if (miPaciente) {
        var miCreatedDate = new Date(miPaciente.created_date); // Convierte la fecha a un objeto Date

        // Filtrar el array para eliminar al paciente con tu id y dejar solo los que tienen una created_date inferior
        var pacientesEnEsperaAnteriores = queue.filter(
          (paciente) =>
            paciente.patientid !== idPaciente &&
            new Date(paciente.created_date) < miCreatedDate
        );
        if (pacientesEnEsperaAnteriores) {
          setPatientsBeforeMe(pacientesEnEsperaAnteriores);
        }
      } else {
        console.error("No se encontró el paciente con el id proporcionado");
      }
    }
  };
  useEffect(() => {
    if (clickButton === FilterType.SEARCH_PROFESSIONAL && data?.specialtyId) {
      getQueue();
      if (!intervalId) {
        intervalId = setInterval(async () => {
          getQueue();
        }, 60000);
      }
    }
    return () => clearInterval(intervalId);
  }, [data?.specialtyId, userToken, clickButton]);

  const getQueueInfo = async () => {
    if (data?.specialtyId && clickButton === FilterType.SEARCH_PROFESSIONAL) {
      await getEstimatedTime(userToken, data?.specialtyId, data?.queueId)
        .then((res) => {
          setQueueLength(res);
        })
        .catch((err) => {
          if (process.env.REACT_APP_DEBUG === "true") {
            const errorMessage =
              "Error:  ~ file: Result index.js:85 ~ getQueueInfo ~ err:" + err;
            showToast(
              toastDispatch,
              errorMessage,
              ToastType.ERROR,
              ToastType.ERROR
            );
          }
        });
    }
  };

  const handleConsultCancelation = () => {
    if (!disableCancelButton) {
      var wsMessage = {
        patientId: data?.patientId,
        orderNumber: data?.orderNumber,
        specialtyId: data?.specialtyId,
        command: "PatientCancel",
        professionalId: data?.professionalId,
        queueId: data?.queueId,
        date: data?.date,
        familymember: data?.familymember,
        reason: data?.reason,
      };
      firebase.put({ ...wsMessage, status: "QUIT" });
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));
      localStorage.setItem("consult", null);
      dispatch({
        type: "LOADING",
        clickButton: FilterType.FINISH_CONSULTATION,
        loading: false,
      });
    }
  };

  /* texto para cantidad de gente delante del paciente
   {queueLength !== 0 ? (<div style={{ padding: "10px"}}>
                     <p>Hay {queueLength} {queueLength === 1 ? "persona" : "personas"} delante de usted.</p>
                  </div>) : (<div style={{ padding: "10px"}}>
                     <p>No hay personas delante de usted.</p>
                     </div>)}
   */

  const renderResult = () => {
    if (clickButton === FilterType.VIRTUAL_GUARD_CALLED) {
      return null;
    }
    if (clickButton === FilterType.TEST_CONNECTION) {
      return <TestConnection />;
    }
    if (clickButton === FilterType.FINISH_CONSULTATION) {
      return <ResultDefault />;
    }
    if (loading) {
      return (
        <div className="list">
          <div className="result__title">
            {!loading && <h2>Profesionales</h2>}
          </div>
          <p className="text-red">Buscando profesionales ...</p>
          <LoadingSpinner />
        </div>
      );
    }
    if (clickButton === FilterType.SEARCH_PROFESSIONAL) {
      //
      return (
        <>
          <div className="result__title">
            {!loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className={MedilineClasses.titleBlue}
                  style={{ margin: "1rem 0 2rem 0", fontSize: "20px" }}
                >
                  Llamando ...
                </div>
                <div>
                  <h3
                    style={{
                      fontSize: "15px",
                      padding: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Aguardá un instante y serás atendido
                  </h3>
                </div>
                <div
                  style={{
                    maxHeight: "400px",
                    minWidth: "400px",
                    overflowX: "auto",
                  }}
                ></div>
                {/* <CustomizedTables data={queue} /> */}
                <div className={`phone`}>
                  <PhoneIcon className="phone-icon" fontSize="large" />
                </div>
                <Button
                    className={MedilineClasses.btnSubmitRed}
                    onClick={handleConsultCancelation}
                    style={{width:"15rem",marginTop: "20px",}}
                  >
                    <b>{"Abandonar Consulta"}</b>
                  </Button>
              </div>
            )}
            {patientsBeforeMe?.length > 0 && (
              <Modal isActive={true} maxWidth={"sm"} hasCloseButton={false}>
                <div className={MedilineClasses.titleBlue}>
                  Hola!! ingresaste a nuestra sala de espera
                </div>
                <div
                  className={MedilineClasses.subtitleBlue}
                >{`Hay ${patientsBeforeMe.length} pacientes para ser atendidos antes que vos.`}</div>
                <div
                  className={MedilineClasses.subtitleBlue}
                >{`Por favor, aguardá y te atenderemos a la brevedad posible`}</div>
                <div style={{display:"flex",justifyContent:"center"}}>
                  <Button
                    className={MedilineClasses.btnSubmitRed}
                    onClick={handleConsultCancelation}
                    style={{width:"15rem"}}
                  >
                    <b>{"Abandonar Sala"}</b>
                  </Button>
                </div>
              </Modal>
            )}
          </div>
        </>
      );
    } else if (clickButton === FilterType.PROFESIONAL_FOUND && profName) {
      return null;
    } else {
      return <ResultDefault />;
    }
  };

  return (
    <div className="result">
      {!clickButton ? <ResultDefault /> : renderResult()}
    </div>
  );
};

export default PatientResult;
