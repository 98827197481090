import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import AccordionCustom from './AccordionCustom';
import ConsultationFormModel from '../PostVideoCallForm/ConsultationFormModel';
import InitialValues from '../PostVideoCallForm/InitialValues';
import ValidationSchema from '../PostVideoCallForm/ValidationSchema';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import TechnicalDifficultiesModal from '../../../../../components/TechnicalDifficultiesModal';
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';
import { ConsultationCreate } from '../../../../../services/consultationService';
import { useStateAuthValue } from '../../../../../context/Auth/AuthState';
import { showToast, ToastType } from '../../../../../utils/ToastUtil';
import { useToast } from '../../../../../context/Toast/ToastProvider';
import { MeetingStatusEnum } from '../../../../../models/enums/MeetingStatusEnum';
import {
   POST_VIDEOCALL_KEY,
   get,
   set,
   removeLocalStorageItem,
} from '../../../../../services/localStorageService';
import { PatientGet } from '../../../../../services/patientService';
import { EventLog } from '../../../../../services/medilinePrepaidService';
import firebase from '../../../../../services/firebase';

const AccordionList = ({ consultationData, setConsultIsSubmitted, setConsultFinishedWithError, setMessage, setPatientLeftProfessionalContainerStyle, setContainerStyle, setPatientFinish, patientFinish, stomp }) => {
   const { formId, formField } = ConsultationFormModel;
   const [initialValues, setInitialValues] = useState({ ...InitialValues });
   const [expanded, setExpanded] = useState(false);
   const [disabledSubmit, setDisabledSubmit] = useState(true);
   const [openModalConf, setOpenModalConf] = useState(false);
   const [openModalTech, setOpenModalTech] = useState(false);
   const [requestConsultation, setRequestConsultation] = useState([]);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [{ userToken, userData }] = useStateAuthValue();
   const [technicalDifValue, setTechnicalDifValue] = useState();
   const [patient, setPatient] = useState({
      first_name: "",
      last_name: ""
   });
   const toastDispatch = useToast();
   let request = { ...consultationData }
   let PATIENT_FINISH = process.env.REACT_APP_FINISH_PATIENT === "true"

   window.onbeforeunload = () => {
      removeLocalStorageItem(POST_VIDEOCALL_KEY)
   }

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted && userToken && consultationData?.familymember) {
         getData();
      }

      return () => {
         isMounted = false;
      };
   }, [consultationData, userToken]);

   useEffect(() => {
      const intervalId = setInterval(async () => {
         await EventLog(userToken, "in call", "profesional", userData)
      }, 15000);
      return () => clearInterval(intervalId);
   }, []);

   const getData = async () => {
      await PatientGet(consultationData?.familymember, userToken, showError)
         .then((response) => {
            setPatient({ ...response?.data, familymember: consultationData?.familymember });
         }).catch(() => showError('Ocurrió un error al obtener los datos del paciente'));
   };

   const confirmConsult = async (value) => {
      setOpenModalConf(false);
      if (value) {
         setIsSubmitting(true);
         await ConsultationCreate(requestConsultation, userToken)
            .then(() => {
               localStorage.setItem('consult', null);
               removeLocalStorageItem(POST_VIDEOCALL_KEY)
               setConsultIsSubmitted(true)
               showToast(toastDispatch, 'Se registró la consulta correctamente', ToastType.SUCCESS);
            })
            .catch(() => {
               showError('Ocurrió un error al obtener la consulta');
               setIsSubmitting(false);
            });
      }
   };

   const handleSubmit = (values, errors) => {
      setOpenModalConf(true);

      request = { ...request, ...values };
      request.status = MeetingStatusEnum.FINISHED;

      //this convertions is necesary to not delay input in formik
      //add Diagnosis because are many field call diagnosis and delay input fields 
      request.diagnosis = values.Diagnosis;
      delete request.Diagnosis;

      if (!values.reason && consultationData.reason) {
         request.reason = consultationData.reason;
      }

      // Prepares post studies
      request.studies = [];
      if (values?.studies?.studyNames?.length && values?.studies?.diagnosis) {
         values.studies.studyNames.forEach(studyItem => request.studies.push({ name: studyItem.name, diagnosis: values.studies.diagnosis }))
      }

      // Prepares post certificates      
      request.certificates = values?.certificates && !errors?.certificates ? [values.certificates] : [];

      request.specialties = [];
      if (values?.specialties?.specialtyNames?.length && values?.specialties?.diagnosis) {
         values.specialties.specialtyNames.forEach(specialty => request.specialties.push({ name: specialty.name, diagnosis: values.specialties.diagnosis, comment: specialty.field }))
      }

      if (request?.medications?.length) {
         request.medications = request.medications.map(medication => {
            const newMedication = { ...medication };
            delete newMedication.id;
            delete newMedication.primary;
            return newMedication;
         })
      }
      request.patient_dto = {
         affiliate_number: patient.patient_dto.affiliateNumber,
         prepaid_health_category: patient.patient_dto.prepaid_health_category,
         attention_type: "VIRTUAL_GUARD"
      },
         request.professional_dto = {
            drName: userData.first_name + " " + userData.last_name,
            professionalId: userData.professional_dto.id,
            professional_name: userData.first_name + " " + userData.last_name,
            professional_license: userData.professional_dto?.licence_str,
            professional_specialties: userData.professional_dto?.specialties,
            email: userData.email,
         }
      setRequestConsultation(request);
   };

   const handleChange = (panel) => (e, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   const saveOnStorage = (values) => {
      if (values && Object.keys(values).length) {
         set(POST_VIDEOCALL_KEY, values);
      }
   };

   const finishWithDifficulties = () => {
      var wsMessage = {
         patientId: consultationData.patientId,
         professionalId: consultationData.professionalId,
         orderNumber: consultationData.orderNumber,
         command: "VideoCallError",
         specialtyId: consultationData.specialtyId,
         queueId: consultationData.queueId,
         date: consultationData.date,
         status: "ERROR",
         error: technicalDifValue
      };
      firebase.put({ ...wsMessage })
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));
      removeLocalStorageItem(POST_VIDEOCALL_KEY)
      localStorage.setItem('consult', null);
      setConsultFinishedWithError(true);
      showToast(toastDispatch, 'Se registró el problema correctamente', ToastType.SUCCESS);
   }

   const finishPatient = () => {
      var wsMessage = {
         patientId: consultationData.patientId,
         professionalId: consultationData.professionalId,
         orderNumber: consultationData.orderNumber,
         command: "PatientFinish",
         specialtyId: consultationData.specialtyId,
         queueId: consultationData.queueId,
         date: consultationData.date,
      };
      firebase.put({ ...wsMessage, status: 'FINISHED' })
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));;
      showToast(toastDispatch, 'Se finalizó el paciente correctamente', ToastType.SUCCESS);
      setPatientFinish(true);
      setPatientLeftProfessionalContainerStyle({ display: "none" });
      setContainerStyle({ display: "inline-block" });
   }

   useEffect(() => {
      let isMounted = true;
      const savedValues = get(POST_VIDEOCALL_KEY);
      if (isMounted && savedValues) {
         setInitialValues(savedValues);
         setDisabledSubmit(
            !savedValues.detail?.length || !savedValues.diagnosis?.length,
         );
      }
      return () => {
         isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={(values, errors) => handleSubmit(values, errors)}
            enableReinitialize
            validateOnMount
         >
            {({ errors, touched, values, setFieldValue }) => {
               return (
                  <Form id={formId}>
                     <div className="accordion-list">
                        <div className="accordion-wrapper">
                           <AccordionCustom
                              title={`Paciente ${patient?.first_name || ''} ${patient?.last_name || ''
                                 }`}
                              type="Name"
                              expanded={expanded}
                              handleChange={handleChange('Name')}
                              patient={patient}
                           ></AccordionCustom>
                           <AccordionCustom
                              required
                              title="Registro de Consulta"
                              type="RC"
                              expanded={expanded}
                              handleChange={handleChange('RC')}
                              formField={formField}
                              hasErrors={
                                 !values.detail ||
                                 !values.Diagnosis ||
                                 (!values.reason && !consultationData.reason) ||
                                 errors?.detail ||
                                 errors?.Diagnosis ||
                                 (errors?.reason && !consultationData.reason)
                              }
                              saveOnStorage={() => saveOnStorage(values)}
                              setDisabledSubmit={setDisabledSubmit}
                              consultation={consultationData}
                           ></AccordionCustom>
                           <AccordionCustom
                              title="Prescripción"
                              type="PC"
                              expanded={expanded}
                              handleChange={handleChange('PC')}
                              formField={formField}
                              setFieldValue={setFieldValue}
                              saveOnStorage={() => saveOnStorage(values)}
                              values={values}
                              errors={errors?.medications}
                              hasErrors={
                                 !values?.medications?.length ||
                                 (touched?.medications && errors?.medications)
                              }
                           ></AccordionCustom>
                           <AccordionCustom
                              title="Certificado Médico"
                              type="C"
                              expanded={expanded}
                              handleChange={handleChange('C')}
                              formField={formField}
                              hasErrors={
                                 !values?.certificates || errors?.certificates
                              }
                              saveOnStorage={() => saveOnStorage(values)}
                              consultation={consultationData}
                           ></AccordionCustom>
                           <AccordionCustom
                              title="Derivación"
                              type="D"
                              expanded={expanded}
                              handleChange={handleChange('D')}
                              formField={formField}
                              values={values?.specialties}
                              setFieldValue={setFieldValue}
                              saveOnStorage={() => saveOnStorage(values)}
                              errors={errors?.specialties}
                              hasErrors={
                                 !values?.specialties?.specialtyNames?.length ||
                                 (touched?.specialties && errors?.specialties)
                              }
                           ></AccordionCustom>
                           <AccordionCustom
                              title="Pedido de estudios"
                              type="PE"
                              expanded={expanded}
                              handleChange={handleChange('PE')}
                              formField={formField}
                              values={values?.studies}
                              setFieldValue={setFieldValue}
                              saveOnStorage={() => saveOnStorage(values)}
                              errors={errors?.studies}
                              hasErrors={
                                 !values?.studies?.studyNames?.length ||
                                 (touched?.studies && errors?.studies)
                              }
                           ></AccordionCustom>
                        </div>
                        <div className="icon-btn-wrapper" style={{ display: "flex", flexDirection: "column", height: "130%", justifyContent: "space-evenly" }}>
                           <Tooltip
                              title={
                                 disabledSubmit ? "Para finalizar la consulta, complete el registro de la misma en Registro de Consulta." : ""}
                           >
                              <Button
                                 type="submit"
                                 className={`btn ${disabledSubmit && 'btn--disabled'
                                    }`}
                                 endIcon={
                                    isSubmitting && <CircularProgress size={24} />
                                 }
                                 onClick={() => {
                                    if (disabledSubmit) {
                                       setMessage("Para finalizar la consulta, complete el registro de la misma.")
                                    } else {
                                       handleSubmit(values, errors)
                                    }
                                 }
                                 }
                              >
                                 Finalizar consulta
                              </Button>
                           </Tooltip>
                           {((!patientFinish && PATIENT_FINISH) && (
                              <Button
                                 type="submit"
                                 className={`btn`}
                                 disabled={patientFinish}
                                 onClick={() => { finishPatient() }}
                              >
                                 Finalizar Paciente
                              </Button>
                           ))}
                           <Tooltip
                              title="Si hubo un problema de conexión de parte del paciente, notifíquelo en el sistema."
                           >
                              <Button
                                 variant="contained"
                                 color="primary"
                                 className={`btn`}
                                 style={{ backgroundColor: "#DA2037", color: "white" }}
                                 type="submit"
                                 onClick={() => {
                                    setOpenModalTech(true);
                                 }
                                 }
                              >
                                 Falla técnica
                              </Button>
                           </Tooltip>
                        </div>
                     </div>
                  </Form>
               );
            }}
         </Formik>
         <ConfirmationModal
            open={openModalConf}
            handleClose={confirmConsult}
            title="¿Desea finalizar la consulta?"
            confirmButton="Finalizar"
            cancelButton="Volver"
         />
         <TechnicalDifficultiesModal
            open={openModalTech}
            setOpen={setOpenModalTech}
            setValue={setTechnicalDifValue}
            handleCloseParent={finishWithDifficulties}
            title="¿Hubo problemas técnicos durante la llamada?"
            confirmButton="Enviar"
            cancelButton="Cancelar"
         />
      </>
   );
};


{/* */ }

export default AccordionList;
